import cookie from 'js-cookie'
import { ACCESS_TOKEN } from './constants'
import { checkForAuthToken } from './auth'

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      path: '/',
    })

    if (key === ACCESS_TOKEN) {
      checkForAuthToken()
    }
  }
}

export const removeCookie = key => {
  if (process.browser) {
    cookie.remove(key)

    if (key === ACCESS_TOKEN) {
      checkForAuthToken()
    }
  }
}

export const getCookie = (key, req) => {
  return process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req)
}

const getCookieFromBrowser = key => {
  return cookie.get(key)
}

const getCookieFromServer = (key, req) => {
  if (!req.headers.cookie) {
    return undefined
  }

  const rawCookie = req.headers.cookie
    .split(';')
    .find(c => c.trim().startsWith(`${key}=`))

  if (!rawCookie) {
    return undefined
  }

  return rawCookie.split('=')[1]
}
