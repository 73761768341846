import { Component } from 'react'
import { db } from 'services/config'
import { removeCookie, getCookie, setCookie } from 'services/cookies'
import { ACCESS_TOKEN, IMP_ID } from 'services/constants'
import { redirect, errorMessage } from 'services/util'
import { identify, track } from 'services/analytics'
import toaster from 'components/toaster'

export const checkForAuthToken = req => {
  let token = getCookie(ACCESS_TOKEN, req)
  let impId = getCookie(IMP_ID, req)

  if (token) {
    // this is fucking hacky, come back to it
    // token has a space, gets URI encoded by something, somewhere
    db.defaults.headers.common['Authorization'] = token.replace('%20', ' ')

    if (impId) {
      db.defaults.headers.common['ImpId'] = impId
    } else {
      delete db.defaults.headers.common['ImpId']
    }
    return true
  } else {
    delete db.defaults.headers.common['Authorization']
    return false
  }
}

export const getCurrentUser = async () => {
  let currentUser = null

  try {
    const res = await db.get('/users/me')
    currentUser = res.data.user
    if (!getCookie(IMP_ID)) {
      identify(currentUser)
    }
  } catch ({ message }) {
    removeCookie(ACCESS_TOKEN)
    removeCookie(IMP_ID)
  }

  return currentUser
}

export const logIn = async ({ email, password }) => {
  let result = {}

  try {
    const res = await db.post('/users/login', {
      email,
      password,
    })

    setCookie(ACCESS_TOKEN, res.data.token)

    result = {
      data: res.data,
      message: null,
    }

    identify({ _id: res.data._id }, { lastLogIn: new Date() })

    track('user_logged_in', {
      userId: res.data._id,
    })
  } catch (e) {
    result = {
      data: null,
      message: errorMessage(e),
    }
  }

  return result
}

export const logOut = () => {
  // make sure to clear user session in global state
  removeCookie(ACCESS_TOKEN)
  removeCookie(IMP_ID)
  global.analytics.reset()
  track('logged_out')
  redirect('/login')
}

export const signUp = async (values = {}, adminCreating = false) => {
  let result = {}

  try {
    const res = await db.post('/users/signup', { ...values, adminCreating })
    const token = res.data.token
    const userObject = { ...values, _id: res.data._id }
    const additionalData = { lastLogIn: new Date().toISOString(), plan: 'tier_1' }

    if (!adminCreating) {
      setCookie(ACCESS_TOKEN, token)
      identify(userObject, additionalData)
      track('user_registered', {
        userId: res.data._id,
        fromPromo: values.promoValue > 0 ? 'yes' : 'no',
      })
    }

    result = {
      data: res.data,
      message: null,
    }
  } catch (e) {
    result = {
      message: errorMessage(e),
      data: null,
    }
  }

  return result
}

export const updateUser = async values => {
  let result = {}

  try {
    const res = await db({
      method: 'PUT',
      url: `/user/me`,
      data: values,
    })

    if (res.data.error) {
      throw res.data.error
    }

    result = {
      message: 'Success',
      data: res.data.data,
    }
  } catch (err) {
    result = {
      message: err.message,
      data: null,
    }
  }

  return result
}

// HOC for any protected routes
export const requireAuth = WrappedComponent => {
  class Enhanced extends Component {
    static async getInitialProps(ctx) {
      let currentUser = null

      if (checkForAuthToken(ctx.req)) {
        currentUser = await getCurrentUser(ctx.req)
      }

      if (!currentUser) {
        if (typeof window !== 'undefined') {
          toaster('You must be logged in to do that', 'error')
        }
        redirect('/login', ctx.res)
        return {}
      }

      const componentProps =
        WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx, currentUser))

      return { ...componentProps, currentUser }
    }

    constructor(props, context) {
      super(props)

      if (process.browser) {
        checkForAuthToken()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  // this is required to carry over static Layout method from original component
  Enhanced.Layout = WrappedComponent.Layout

  return Enhanced
}

export const hasAuthToken = req => !!getCookie(ACCESS_TOKEN, req)
